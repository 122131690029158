import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/header";
import Layout from "../../components/layout";
import Hero from "../../components/hero-eazy";
import EazyHeader from "../../components/header-eazy";
import WithProviders from "../../components/WithProviders";

import uniqueOnlineStoreIcon from "../images/online-store-icon.svg";
import uniqueOnlineStore from "../images/unique-online-store.png";
import uniqueOnlineStore2 from "../images/unique-online-store@2x.png";
import uniqueOnlineStore3 from "../images/unique-online-store@3x.png";

import qualityWebsiteIcon from "../images/quality-website-icon.svg";
import qualityWebsite from "../images/quality-website.png";
import qualityWebsite2 from "../images/quality-website@2x.png";
import qualityWebsite3 from "../images/quality-website@3x.png";

import goMobileIcon from "../images/go-mobile-icon.svg";
import goMobile from "../images/go-mobile.png";
import goMobile2 from "../images/go-mobile@2x.png";
import goMobile3 from "../images/go-mobile@3x.png";

import totalControlIcon from "../images/total-control-icon.svg";
import totalControl from "../images/total-control.png";
import totalControl2 from "../images/total-control@2x.png";
import totalControl3 from "../images/total-control@3x.png";

import manageIcon from "../images/manage-icon.svg";
import manage from "../images/manage.png";
import manage2 from "../images/manage@2x.png";
import manage3 from "../images/manage@3x.png";

import yourCustomerIcon from "../images/your-customer-icon.svg";
import yourCustomer from "../images/your-customer.png";
import yourCustomer2 from "../images/your-customer@2x.png";
import yourCustomer3 from "../images/your-customer@3x.png";

class Features extends React.Component {
  render() {
    const { language, updateLanguage, direction } = this.props;
    return (
      <Layout showFooterForm language={language} location={this.props.location}>
        <div className="eazy-page">
          <Helmet title="SmartStore Eazy - ZopSmart" />
          <Header
            eazy
            language={language}
            updateLanguage={updateLanguage}
            direction={direction}
          />
          <EazyHeader
            links={[
              {
                href: "https://dashboard.zopsmart.com/login",
                className: "smartstore-login",
                label: "login",
              },
              {
                href: "https://dashboard.zopsmart.com/signup",
                className: "smartstore-signup",
                label: "sign up",
              },
            ]}
            current="home"
          />
          <Hero />
          <div className="eazy">
            <section className="intro tc">
              <h1>Key Features</h1>
              <p>Everything you need to start selling online</p>
              <hr />
            </section>
            <section className="features wrap">
              <section className="feature unique-online-store">
                <div className="feature-media">
                  <img
                    src={uniqueOnlineStoreIcon}
                    className="feature-icon"
                    alt=""
                    width="90"
                    loading="lazy"
                  />
                  <img
                    src={uniqueOnlineStore}
                    srcSet={`${uniqueOnlineStore2} 2x, ${uniqueOnlineStore3} 3x`}
                    alt="unique online store"
                    width="491"
                    height="282"
                    loading="lazy"
                  />
                </div>
                <div className="feature-details">
                  <h2>Get your unique online store</h2>
                  <p>Stand-out in the crowd by creating your unique wesbite</p>
                  <ul>
                    <li>
                      Use your own domain name and increase your brand value
                    </li>
                    <li>Choose colors and themes that reflect your brand</li>
                    <li>
                      Enhance your website by uploading attractive banners
                    </li>
                  </ul>
                </div>
              </section>

              <section className="feature quality-website rev">
                <div className="feature-media">
                  <img
                    src={qualityWebsite}
                    srcSet={`${qualityWebsite2} 2x, ${qualityWebsite3} 3x`}
                    alt="international quality website"
                    loading="lazy"
                  />
                  <img
                    src={qualityWebsiteIcon}
                    className="feature-icon"
                    alt=""
                    width="90"
                    loading="lazy"
                  />
                </div>
                <div className="feature-details">
                  <h2>Set up your shop easily</h2>
                  <p>Create your own shop - No technical skills required</p>
                  <ul>
                    <li>
                      Use our step by step guide to easily set-up your online
                      shop
                    </li>
                    <li>
                      Make all changes using simple text boxes, drop-downs{" "}
                      <br />
                      and drag & drop menus
                    </li>
                    <li>
                      Create your catalogue, set prices, set shipping rules and
                      <br /> start selling online
                    </li>
                  </ul>
                </div>
              </section>

              <section className="feature go-mobile">
                <div className="feature-media">
                  <img
                    src={goMobileIcon}
                    className="feature-icon"
                    alt=""
                    width="90"
                    loading="lazy"
                  />
                  <img
                    src={goMobile}
                    srcSet={`${goMobile2} 2x, ${goMobile3} 3x`}
                    alt="go mobile instantly"
                    loading="lazy"
                  />
                </div>
                <div className="feature-details">
                  <h2>Go mobile instantly</h2>
                  <p>Get additional sales by selling on mobile devices</p>
                  <ul>
                    <li>
                      Start selling on mobile instantly as your website will
                      look
                      <br /> even more stunning on mobile devices
                    </li>
                    <li>
                      Enable shopping convenience as shopping cart will be
                      <br /> common across devices
                    </li>
                    <li>
                      Get your own international quality Android and iOS app
                    </li>
                  </ul>
                </div>
              </section>

              <section className="feature manage rev">
                <div className="feature-media">
                  <img
                    src={manage}
                    srcSet={`${manage2} 2x, ${manage3} 3x`}
                    alt="manage e-commerce website on the go"
                    loading="lazy"
                  />
                  <img
                    src={totalControlIcon}
                    className="feature-icon"
                    alt=""
                    width="90"
                    loading="lazy"
                  />
                </div>
                <div className="feature-details">
                  <h2>Manage it on-the-go</h2>
                  <p>Easily access your shop-admin on your Android mobile</p>
                  <ul>
                    <li>Update prices, stock and images</li>
                    <li>
                      Get alerts on important events such as order placement,
                      <br /> payment and cancellation
                    </li>
                    <li>
                      Reply to customer emails and chats without any delay
                    </li>
                  </ul>
                </div>
              </section>

              <section className="feature your-customer">
                <div className="feature-media">
                  <img
                    src={manageIcon}
                    className="feature-icon"
                    alt=""
                    width="90"
                    loading="lazy"
                  />
                  <img
                    src={yourCustomer}
                    srcSet={`${yourCustomer2} 2x, ${yourCustomer3} 3x`}
                    alt="reach out to your customer with powerful SEO and notifications"
                    loading="lazy"
                  />
                </div>
                <div className="feature-details">
                  <h2>Reach out to your customers</h2>
                  <p>Get additional sales with new and repeat customers</p>
                  <ul>
                    <li>Get new customers with our powerful SEO</li>
                    <li>
                      Create offers and coupons targeting a segment of your
                      customers
                    </li>
                    <li>
                      Run targeted marketing campaigns via SMS, Emails and{" "}
                      <br />
                      App-notifications to specific customers
                    </li>
                  </ul>
                </div>
              </section>

              <section className="feature total-control rev">
                <div className="feature-media">
                  <img
                    src={totalControl}
                    srcSet={`${totalControl2} 2x, ${totalControl3} 3x`}
                    alt="be in total control of the e-commerce website"
                    loading="lazy"
                  />
                  <img
                    src={yourCustomerIcon}
                    className="feature-icon"
                    alt=""
                    width="90"
                    loading="lazy"
                  />
                </div>
                <div className="feature-details">
                  <h2>Manage your customers</h2>
                  <p>Use our powerful CRM to build loyalty</p>
                  <ul>
                    <li>
                      View all your customers in one place with their detailed
                      purchase&nbsp;history
                    </li>
                    <li>
                      Upload details of your offline-customers to provide them
                      the <br />
                      same level of service
                    </li>
                    <li>
                      Add or edit details such as address, email and phone{" "}
                      <br />
                      number of your customers
                    </li>
                  </ul>
                </div>
              </section>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default WithProviders(Features);
